<template>
  <div class="detected">
    <div class="modal__body detected__body">
      <div class="detected__title">
        {{ title }}
      </div>
      <div class="detected__text">
        {{ text }}
      </div>
      <div class="detected__divider" />
    </div>
    <div class="detected__footer">
      <button
        class="button button_secondary button_md detected__capitalized-button"
        @click="onContinue"
      >
        Yes - that was my best effort. Continue
      </button>
      <div class="detected__button-divider">or</div>
      <button
        class="button button_secondary button_md detected__capitalized-button"
        @click="onClose"
      >
        No - I'd like to try again
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  MSG_NO_COUGH_DETECTED_TITLE,
  MSG_NO_COUGH_DETECTED_TEXT,
  STORE_SET_PASSED,
  ROUTE_THREE_STRONG_COUGHS 
} from "@/constants";

export default {
  props: {
    options: { type: Object, required: true },
  },
  data() {
    return {
      title: MSG_NO_COUGH_DETECTED_TITLE,
      text: MSG_NO_COUGH_DETECTED_TEXT,
    };
  },
  methods: {
    ...mapActions([STORE_SET_PASSED]),
    async onClose() {
      this.options.deleteAction();
      await this[STORE_SET_PASSED]({ [ROUTE_THREE_STRONG_COUGHS]: false });
      this.$router.push({ name: ROUTE_THREE_STRONG_COUGHS });
      this.$emit("close");
    },
    async onContinue() {
      this.$emit("close");
    },
  },
};
</script>
